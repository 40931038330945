import firebase from 'firebase'
import "firebase/auth"
import "firebase/database"
import "firebase/storage"


var firebaseConfig = {
    apiKey: "AIzaSyAE_HG51Nja-SeKbLmiMM1OS9qBQZGqSCc",
    authDomain: "iccoc-fdd3a.firebaseapp.com",
    databaseURL: "https://iccoc-fdd3a-default-rtdb.firebaseio.com",
    projectId: "iccoc-fdd3a",
    storageBucket: "iccoc-fdd3a.appspot.com",
    messagingSenderId: "613729869170",
    appId: "1:613729869170:web:d42d6c02c60b5f866351eb",
    measurementId: "G-RYTVK9T5VF"
};
// Initialize Firebase
const database = firebase.initializeApp(firebaseConfig)

export default database
