import React from "react";
import firebase from "../firebase";
import "../App.css";

import { Grid, Form, Segment, Button, Message } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

class Register extends React.Component {
	state = {
		chuchArea: "",
		churchEmail: "",
		churchPassword: "",
		churchPasswordConfirmation: "",
		errors: [],
		loading: false,
	};

	isFormValid = () => {
		let errors = [];
		let error;

		if (this.isFormEmpty(this.state)) {
			error = { message: "Fill in all fields" };
			this.setState({ errors: errors.concat(error) });
			return false;
		} else if (!this.isPasswordValid(this.state)) {
			error = { message: "Password is invalid" };
			this.setState({ errors: errors.concat(error) });
			return false;
		}
		// } else if (!this.state.step1 || !this.state.step2) {
		//     return false
		// }
		else {
			return true;
		}
	};

	isFormEmpty = ({
		chuchArea,
		churchEmail,
		churchPassword,
		churchPasswordConfirmation,
	}) => {
		return (
			!chuchArea.length ||
			!churchEmail.length ||
			!churchPassword.length ||
			!churchPasswordConfirmation.length
		);
	};

	isPasswordValid = ({ churchPassword, churchPasswordConfirmation }) => {
		if (churchPassword.length < 6 || churchPasswordConfirmation.length < 6) {
			return false;
		} else if (churchPassword !== churchPasswordConfirmation) {
			return false;
		} else {
			return true;
		}
	};

	displayErrors = (errors) =>
		errors.map((error, i) => <p key={i}>{error.message}</p>);

	handleChange = (event) => {
		if (event.target.name === "churchArea")
			event.target.value =
				event.target.value.charAt(0).toLowerCase() +
				event.target.value.slice(1);
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.stepOneClicked && this.props.stepTwoClicked) {
			if (this.isFormValid()) {
				// console.log(this.state.chuchArea);
				this.props.onChangeValue(this.state.chuchArea);
				this.props.onSubmission(false);
				this.setState({ errors: [], loading: true });
				firebase
					.auth()
					.createUserWithEmailAndPassword(
						this.state.churchEmail,
						this.state.churchPassword
					)
					.then((createdUser) => {
						firebase
							.auth()
							.currentUser.updateProfile({
								displayName: this.state.chuchArea,
							})
							.then(function () {
								// console.log("Updated");
							})
							.catch((err) => {
								// console.error(err);
								this.setState({
									errors: this.state.errors.concat(err),
									loading: false,
								});
							});
						// console.log(createdUser);
						this.setState({ loading: false });
					})
					.catch((err) => {
						// console.error(err);
						this.setState({
							errors: this.state.errors.concat(err),
							loading: false,
						});
					});
			}
		} else {
			this.setState({
				errors: this.state.errors.concat({
					message:
						"Sorry, We can't process your registration without agreements",
				}),
			});
		}
	};

	// saveUser = createdUser => {
	//     return this.state.usersRef.child(createdUser.user.uid).set({
	//         name: createdUser.user.displayName,
	//         avatar: createdUser.user.photoURL
	//     });
	// };

	handleInputError = (errors, inputName) => {
		return errors.some((error) =>
			error.message.toLowerCase().includes(inputName)
		)
			? "error"
			: "";
	};

	render() {
		const {
			chuchArea,
			churchEmail,
			churchPassword,
			churchPasswordConfirmation,
			errors,
			loading,
		} = this.state;

		return (
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column style={{ maxWidth: 450 }}>
					<Form onSubmit={this.handleSubmit} size="large" className="step4">
						<Segment stacked>
							<h2>Enter your details to register</h2>
							<Form.Input
								fluid
								name="chuchArea"
								icon="user"
								iconPosition="left"
								placeholder="Area"
								onChange={this.handleChange}
								value={chuchArea}
								type="text"
							/>

							<Form.Input
								fluid
								name="churchEmail"
								icon="mail"
								iconPosition="left"
								placeholder="Email Address"
								onChange={this.handleChange}
								value={churchEmail}
								className={this.handleInputError(errors, "email")}
								type="email"
							/>

							<Form.Input
								fluid
								name="churchPassword"
								icon="lock"
								iconPosition="left"
								placeholder="Password"
								onChange={this.handleChange}
								value={churchPassword}
								className={this.handleInputError(errors, "password")}
								type="password"
							/>

							<Form.Input
								fluid
								name="churchPasswordConfirmation"
								icon="repeat"
								iconPosition="left"
								placeholder="Password Confirmation"
								onChange={this.handleChange}
								value={churchPasswordConfirmation}
								className={this.handleInputError(errors, "password")}
								type="password"
							/>

							<Button
								disabled={loading}
								className={loading ? "loading" : ""}
								color="orange"
								fluid
								size="large"
							>
								Submit
							</Button>
						</Segment>
					</Form>
					{errors.length > 0 && (
						<Message error>
							<h3>Error</h3>
							{this.displayErrors(errors)}
						</Message>
					)}
				</Grid.Column>
			</Grid>
		);
	}
}

export default Register;
