/* eslint-disable no-unused-vars */
import firebase from "../firebase";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "./Card";
import csc from "country-state-city";
import { Form, Button } from "semantic-ui-react";
class Search extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			churchCountry: "",
			churchState: "",
			churches: [],
			countryOptions1: [],
			stateOptions1: [{ key: "null", text: "Select Country First" }],
		};
	}

	componentDidMount() {
		let countryOptions1 = csc.getAllCountries().map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.isoCode,
			};
		});

		this.setState({ countryOptions1: countryOptions1 });
	}

	handleChangeCountry = (event, data) => {
		this.setState({ churchCountry: csc.getCountryByCode(data.value).name });

		let stateOptions1 = csc.getStatesOfCountry(data.value).map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.name,
			};
		});
		this.setState({ stateOptions1: stateOptions1 });
	};
	handleChangeState = (event, data) => {
		this.setState({ churchState: data.value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		// console.log(this.state.churchCountry);
		// console.log(this.state.churchState);
		if (this.state.churchCountry && this.state.churchState) {
			firebase
				.database()
				.ref(
					"churches/" + this.state.churchCountry + "/" + this.state.churchState
				)
				.once("value")
				.then((snapshot) => {
					let churches = [];
					snapshot.forEach((childSnapshot) => {
						churches.push(childSnapshot.val());
					});
					this.setState({ churches: churches });
					// console.log(this.state.churches);
				});
		} else alert("Please fill Country and state");
	};

	render() {
		return (
			<div>
				<Row>
					<Col xl={4}>
						<Row>
							<Col xl={12} xs={{ span: 10, offset: 1 }} className="search-text">
								<Row>
									<Col xl={12} className="searchLeftMain">
										<h2 className="search-text-find">
											Search for a Congregation
										</h2>
									</Col>
								</Row>
								<Row>
									<Col>
										<p>Enter Country and Province</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xl={{ span: 8, offset: 2 }}>
								<Row></Row>

								<Row>
									<Col xl={12} xs={{ span: 8, offset: 2 }}>
										<Form onSubmit={this.handleSubmit} size="large">
											<Form.Dropdown
												placeholder="Select Country"
												name="churchCountry"
												fluid
												search
												selection
												onChange={this.handleChangeCountry}
												options={this.state.countryOptions1}
											/>
											<Form.Dropdown
												placeholder="Select Province/State"
												name="churchState"
												fluid
												search
												selection
												onChange={this.handleChangeState}
												options={this.state.stateOptions1}
											/>
											<Button color="orange" fluid size="large">
												Submit
											</Button>
										</Form>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xl={8}>
						<Col xl={{ span: 7, offset: 2 }} xs={{ span: 10, offset: 1 }}>
							<div className="search-result-box">
								{this.state.churches.map((churchInfo, index) => {
									return <Card key={index} churchInfo={churchInfo} />;
								})}
							</div>
						</Col>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Search;
