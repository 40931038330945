/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import firebase from "./firebase";
import { Button } from "semantic-ui-react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Navbar, Row, Nav, NavDropdown } from "react-bootstrap";

import globe from "./assets/globe.png";
import gif from "./assets/gif.gif";
import logo from "./assets/logo.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Aim from "./components/Aim";
import Admin from "./components/Admin";
import Register from "./components/Register";
import Contact from "./components/Contact";
import Faith from "./components/Faith";
import Login from "./components/Login";
import StepFour from "./components/step4";
import Search from "./components/Search";
import "semantic-ui-css/semantic.min.css";

class App extends React.Component {
	// componentDidMount() {
	//   firebase.auth().onAuthStateChanged(user => {
	//     if (user) {
	//       this.props.history.push("/admin");
	//     }
	//   });
	// }
	componentDidMount() {
		firebase
			.auth()
			.signOut()
			.then(() => {
				console.log("Signedout");
			})
			.catch((error) => {
				// An error happened.
			});
	}

	render() {
		return (
			<Router>
				<div className="App">
					<Navbar
						fixed="top"
						collapseOnSelect
						expand="lg"
						bg="white"
						variant="light"
					>
						<Navbar.Brand href="#home">
							<div className="App-logo">
								<img className="globe" src={globe} alt="First slide" />
								<img className="logo" src={logo} alt="First slide" />
							</div>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link>
									<Link to="/">
										<Button basic color="black">
											<p>Search</p>
										</Button>
									</Link>
								</Nav.Link>

								<Nav.Link>
									<Link to="/aim">
										<Button basic color="black">
											<p>About Us</p>
										</Button>
									</Link>
								</Nav.Link>

								<Nav.Link>
									<Link to="/login">
										<Button basic color="black">
											<p>Login</p>
										</Button>
									</Link>
								</Nav.Link>
							</Nav>
							<Nav>
								<Nav.Link>
									<Link to="/register">
										<Button basic color="green">
											<p>Register Your Local Church</p>
										</Button>
									</Link>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>

					<div className="searchLeft">
						<Switch>
							<Route exact path="/">
								<Search />
							</Route>
							<Route exact path="/aim">
								<Aim />
							</Route>

							<Route exact path="/login">
								<Login />
							</Route>
							<Route exact path="/register">
								<Register />
							</Route>
							<Route path="/admin">
								{" "}
								<Admin />{" "}
							</Route>
						</Switch>
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
