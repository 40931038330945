/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import firebase from "../firebase";
import "../App.css";

import React from "react";
import { Grid, Form, Segment, Button } from "semantic-ui-react";
import csc from "country-state-city";

// const countryOptions1 = [
//     { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
//     { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
//     { key: 'al', value: 'al', flag: 'al', text: 'Albania' }
// ]

class StepFour extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			churchArea: this.props.churchArea,
			churchDob: "",
			churchCount: "",
			churchServiceTime: "",
			churchBibleClassDay: "",
			churchbibleClasstime: "",
			churchCountry: "",
			churchState: "",
			churchHouseNumber: "",
			churchStreet: "",
			churchCity: "",
			churchPhoneNumber: "",
			churchPreacherLastName: "",
			churchPreacherFirstName: "",
			churchEmail: "",
			password: "",
			churchPinCode: "",
			passwordConfirmation: "",
			errors: [],
			loading: false,
			usersRef: firebase.database().ref("users"),
			countryOptions1: [],
			stateOptions1: [{ key: "null", text: "Select Country First" }],
			bibleClassDay: [{ key: "null", text: "Select Day" }],
			show: true,
		};
	}

	componentDidMount() {
		let countryOptions1 = csc.getAllCountries().map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.isoCode,
			};
		});

		let bibleClassDay = [
			{
				key: "Monday",
				text: "Monday",
				value: "Monday",
			},
			{
				key: "Tuesday",
				text: "Tuesday",
				value: "Tuesday",
			},
			{
				key: "Wednesday",
				text: "Wednesday",
				value: "Wednesday",
			},
			{
				key: "Thursday",
				text: "Thursday",
				value: "Thursday",
			},
			{
				key: "Friday",
				text: "Friday",
				value: "Friday",
			},
			{
				key: "Saturday",
				text: "Saturday",
				value: "Saturday",
			},
		];

		this.setState({
			countryOptions1: countryOptions1,
			bibleClassDay: bibleClassDay,
			churchArea: this.props.churchArea,
		});

		// let stateOptions1 = csc.getAllStates().map((index) => {

		//     return {

		//         key: index.isoCode,
		//         text: index.name,
		//         value: index.isoCode
		//     }

		// })
		// this.setState({ stateOptions1: stateOptions1 })
	}

	componentDidUpdate() {}

	isFormValid = () => {
		let errors = [];
		let error;

		if (this.isFormEmpty(this.state)) {
			// console.log("here");
			error = { message: "Fill in all fields" };
			this.setState({ errors: errors.concat(error) });
			return false;
		} else {
			return true;
		}
	};

	isFormEmpty = ({
		churchArea,
		churchHouseNumber,
		churchDob,
		churchPreacherFirstName,
		churchPreacherLastName,
		churchServiceTime,
		churchState,
		churchCity,
		churchCountry,
		churchPinCode,
		churchPhoneNumber,
	}) => {
		return (
			!churchArea.length ||
			!churchHouseNumber.length ||
			!churchDob.length ||
			!churchPinCode.length ||
			!churchPreacherFirstName.length ||
			!churchPreacherLastName.length ||
			!churchServiceTime.length ||
			!churchState.length ||
			!churchCity.length ||
			!churchCountry.length ||
			!churchPhoneNumber.length
		);
	};

	isPasswordValid = ({ password, passwordConfirmation }) => {
		if (password.length < 6 || passwordConfirmation.length < 6) {
			return false;
		} else if (password !== passwordConfirmation) {
			return false;
		} else {
			return true;
		}
	};

	displayErrors = (errors) =>
		errors.map((error, i) => <p key={i}>{error.message}</p>);

	handleChange = (event) => {
		if (event.target.name === "churchArea")
			event.target.value =
				event.target.value.charAt(0).toLowerCase() +
				event.target.value.slice(1);
		// console.log(event.target.value);
		this.setState({ [event.target.name]: event.target.value });
		// console.log(event.target.value);
	};
	handleChangeCountry = (event, data) => {
		this.setState({ churchCountry: csc.getCountryByCode(data.value).name });
		// console.log(this.state.churchCountry);
		let stateOptions1 = csc.getStatesOfCountry(data.value).map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.name,
			};
		});
		this.setState({ stateOptions1: stateOptions1 });
	};
	handleChangeState = (event, data) => {
		this.setState({ churchState: data.value });
	};
	handleBibleClassDay = (event, data) => {
		this.setState({ churchBibleClassDay: data.value });
	};
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.isFormValid()) {
			this.setState({ errors: [], loading: true });
			firebase
				.database()
				.ref(
					"churches/" + this.state.churchCountry + "/" + this.state.churchState
				)
				.update({
					[firebase.auth().currentUser.displayName]: {
						churchArea: firebase.auth().currentUser.displayName,
						churchDob: this.state.churchDob,
						churchCount: this.state.churchCount,
						churchState: this.state.churchState,
						churchServiceTime: this.state.churchServiceTime,
						churchBibleClassDay: this.state.churchBibleClassDay,
						churchbibleClasstime: this.state.churchbibleClasstime,
						churchCountry: this.state.churchCountry,
						churchHouseNumber: this.state.churchHouseNumber,
						churchStreet: this.state.churchStreet,
						churchPinCode: this.state.churchPinCode,
						churchPreacherFirstName: this.state.churchPreacherFirstName,
						churchPreacherLastName: this.state.churchPreacherLastName,
						churchCity: this.state.churchCity,
						churchPhoneNumber: this.state.churchPhoneNumber,
					},
				})
				.then(() => {
					// console.log("user saved");
					firebase
						.auth()
						.signOut()
						.then(() => {
							alert("Account Succesfully Created");
							this.setState({ show: false });
						})
						.catch((error) => {
							// An error happened.
						});
				})
				.catch((err) => {
					// console.error(err);
					this.setState({
						errors: this.state.errors.concat(err),
						loading: false,
					});
				});
		} else {
			console.log("not valid");
		}
	};

	saveUser = (createdUser) => {
		return this.state.usersRef.child(createdUser.user.uid).set({
			name: createdUser.user.displayName,
			avatar: createdUser.user.photoURL,
		});
	};

	handleInputError = (errors, inputName) => {
		return errors.some((error) =>
			error.message.toLowerCase().includes(inputName)
		)
			? "error"
			: "";
	};

	render() {
		const {
			churchArea,
			churchDob,
			churchCount,
			churchServiceTime,
			churchbibleClassDay,
			churchbibleClasstime,
			churchPreacherFirstName,
			churchPreacherLastName,
			churchCountry,
			churchBibleClassDay,
			churchHouseNumber,
			churchStreet,
			churchCity,
			churchPinCode,
			churchPhoneNumber,
			churchEmail,
			password,
			passwordConfirmation,
		} = this.state;

		let myComponent;
		if (this.state.show) {
			myComponent = (
				<Router>
					<Switch>
						<Route exact path="/register">
							<Grid textAlign="center" verticalAlign="middle" className="app">
								<Grid.Column style={{ maxWidth: 550 }}>
									<Form
										onSubmit={this.handleSubmit}
										size="large"
										className="step4"
									>
										<Segment stacked>
											<Form.Field>
												<label>Area</label>

												<Form.Input
													disabled
													fluid
													name="churchArea"
													icon="user"
													iconPosition="left"
													placeholder="Ex: Gunadala"
													onChange={this.handleChange}
													value={churchArea}
													type="text"
												/>
											</Form.Field>
											<label>Name of Preacher</label>
											<Form.Group widths="equal">
												<Form.Input
													fluid
													name="churchPreacherFirstName"
													icon="user"
													iconPosition="left"
													placeholder="First name"
													onChange={this.handleChange}
													value={churchPreacherFirstName}
													type="text"
												/>
												<Form.Input
													fluid
													name="churchPreacherLastName"
													icon="user"
													iconPosition="left"
													placeholder="Last name"
													onChange={this.handleChange}
													value={churchPreacherLastName}
													type="text"
												/>
											</Form.Group>
											<Form.Field>
												<label>Date Of Innaguration</label>

												<Form.Input
													fluid
													name="churchDob"
													icon="date"
													iconPosition="left"
													placeholder=""
													value={churchDob}
													onChange={this.handleChange}
													type="date"
												/>
											</Form.Field>

											<Form.Field>
												<label>No. of Members</label>

												<Form.Input
													fluid
													name="churchCount"
													icon="date"
													iconPosition="left"
													placeholder="No. of members"
													onChange={this.handleChange}
													value={churchCount}
													type="text"
												/>
											</Form.Field>
											<Form.Field>
												<label>Sunday Service Timings</label>

												<Form.Input
													fluid
													name="churchServiceTime"
													icon="date"
													iconPosition="left"
													placeholder="No. of members"
													onChange={this.handleChange}
													value={churchServiceTime}
													type="time"
												/>
											</Form.Field>

											<Form.Field>
												<label>Weekly Buible Class Timings</label>

												<Form.Group widths="equal">
													<Form.Dropdown
														placeholder="Select Day"
														name="churchBibleClassDay"
														fluid
														search
														selection
														onChange={this.handleBibleClassDay}
														options={this.state.bibleClassDay}
													/>

													<Form.Input
														fluid
														name="churchbibleClasstime"
														icon="date"
														iconPosition="left"
														placeholder=""
														onChange={this.handleChange}
														value={churchbibleClasstime}
														type="time"
													/>
												</Form.Group>
											</Form.Field>

											<Form.Field>
												<label>Address</label>

												<Form.Dropdown
													placeholder="Select Country"
													name="churchCountry"
													fluid
													search
													selection
													onChange={this.handleChangeCountry}
													options={this.state.countryOptions1}
												/>

												<Form.Input
													fluid
													name="churchHouseNumber"
													icon="date"
													iconPosition="left"
													placeholder="Door Number"
													onChange={this.handleChange}
													value={churchHouseNumber}
													type="text"
												/>
												<Form.Input
													fluid
													name="churchStreet"
													icon="date"
													iconPosition="left"
													placeholder="Street"
													onChange={this.handleChange}
													value={churchStreet}
													type="text"
												/>
												<Form.Input
													fluid
													name="churchCity"
													icon="date"
													iconPosition="left"
													placeholder="City"
													onChange={this.handleChange}
													value={churchCity}
													type="text"
												/>
												<Form.Input
													fluid
													name="churchPinCode"
													icon="date"
													iconPosition="left"
													placeholder="Pin/Zip code"
													onChange={this.handleChange}
													value={churchPinCode}
													type="text"
												/>

												<Form.Dropdown
													placeholder="Select Province/State"
													name="churchState"
													fluid
													search
													selection
													onChange={this.handleChangeState}
													options={this.state.stateOptions1}
												/>
												<Form.Input
													fluid
													name="churchPhoneNumber"
													icon="date"
													iconPosition="left"
													placeholder="Phone Number"
													onChange={this.handleChange}
													value={churchPhoneNumber}
													type="text"
												/>
											</Form.Field>

											<Button color="orange" fluid size="large">
												Submit
											</Button>
										</Segment>
									</Form>
								</Grid.Column>
							</Grid>
						</Route>
					</Switch>
				</Router>
			);
		}
		return <div>{myComponent}</div>;
	}
}

export default StepFour;
