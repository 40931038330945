function Faith() {
    return (
        < div >
            <h1>This is Faith Page</h1>
        </div >

    );
}

export default Faith;
