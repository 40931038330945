/* eslint-disable no-unused-vars */
import firebase from "../firebase";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import icon from "../assets/icon.png";
import { Form, Segment, Button, Header } from "semantic-ui-react";
import "../App.css";

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			churchArea: "",
			churchDob: "",
			churchCount: "",
			churchServiceTime: "",
			churchbibleClassDay: "",
			churchBibleClasstime: "",
			churchCountry: "",
			churchState: "",
			churchPreacherFirstName: "",
			churchPreacherLastName: "",
			churchHouseNumber: "",
			churchStreet: "",
			churchCity: "",
			churchPhoneNumber: "",
			churchPinCode: "",
			churchEmail: "",
			password: "",
			passwordConfirmation: "",
			errors: [],
			loading: false,
			usersRef: firebase.database().ref("users"),
			countryOptions1: [],
			bibleClassDay: [
				{
					key: "this.props.churchInfo.churchBibleClassDay",
					text: "this.props.churchInfo.churchBibleClassDay",
				},
			],
			stateOptions1: [{ key: "null", text: "Select Country First" }],
			showComponent: true,
		};
		this.state = {
			churches: [],
		};
	}
	componentDidMount() {
		let bibleClassDay = [
			{
				key: "Monday",
				text: "Monday",
				value: "Monday",
			},
			{
				key: "Tuesday",
				text: "Tuesday",
				value: "Tuesday",
			},
			{
				key: "Wednesday",
				text: "Wednesday",
				value: "Wednesday",
			},
			{
				key: "Thursday",
				text: "Thursday",
				value: "Thursday",
			},
			{
				key: "Friday",
				text: "Friday",
				value: "Friday",
			},
			{
				key: "Saturday",
				text: "Saturday",
				value: "Saturday",
			},
		];
		this.setState({
			bibleClassDay: bibleClassDay,
			churchCountry: this.props.churchInfo.churchCountry,
			churchState: this.props.churchInfo.churchState,
			churchArea: this.props.churchInfo.churchArea,
			churchDob: this.props.churchInfo.churchDob,
			churchCount: this.props.churchInfo.churchCount,
			churchPreacherFirstName: this.props.churchInfo.churchPreacherFirstName,
			churchPreacherLastName: this.props.churchInfo.churchPreacherLastName,
			churchServiceTime: this.props.churchInfo.churchServiceTime,
			churchBibleClassDay: this.props.churchInfo.churchBibleClassDay,
			churchbibleClasstime: this.props.churchInfo.churchbibleClasstime,
			churchHouseNumber: this.props.churchInfo.churchHouseNumber,
			churchStreet: this.props.churchInfo.churchStreet,
			churchCity: this.props.churchInfo.churchCity,
			churchPhoneNumber: this.props.churchInfo.churchPhoneNumber,
			churchPinCode: this.props.churchInfo.churchPinCode,
			showComponent: true,
		});
	}

	isFormValid = () => {
		let errors = [];
		let error;

		if (this.isFormEmpty(this.state)) {
			error = { message: "Fill in all fields" };
			this.setState({ errors: errors.concat(error) });
			return false;
		} else {
			return true;
		}
	};

	isFormEmpty = ({
		churchHouseNumber,
		churchDob,
		churchPreacherFirstName,
		churchPreacherLastName,
		churchServiceTime,
		churchState,
		churchCity,
		churchCountry,
		churchPinCode,
		churchPhoneNumber,
	}) => {
		return (
			!churchHouseNumber.length ||
			!churchDob.length ||
			!churchPinCode.length ||
			!churchPreacherFirstName.length ||
			!churchPreacherLastName.length ||
			!churchServiceTime.length ||
			!churchState.length ||
			!churchCity.length ||
			!churchCountry.length ||
			!churchPhoneNumber.length
		);
	};

	displayErrors = (errors) =>
		errors.map((error, i) => <p key={i}>{error.message}</p>);

	handleChange = (event) => {
		if (event.target.name === "churchArea")
			event.target.value =
				event.target.value.charAt(0).toLowerCase() +
				event.target.value.slice(1);
		this.setState({ [event.target.name]: event.target.value });
		console.log(event.target.value);
	};
	handleBibleClassDay = (event, data) => {
		this.setState({ churchBibleClassDay: data.value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (true) {
			this.setState({ errors: [], loading: true });
			firebase
				.database()
				.ref(
					"churches/" +
						this.state.churchCountry +
						"/" +
						this.state.churchState +
						"/" +
						this.state.churchArea
				)
				.update({
					churchArea: this.state.churchArea,
					churchDob: this.state.churchDob,
					churchCount: this.state.churchCount,
					churchState: this.state.churchState,
					churchServiceTime: this.state.churchServiceTime,
					churchBibleClassDay: this.state.churchBibleClassDay,
					churchbibleClasstime: this.state.churchbibleClasstime,
					churchCountry: this.state.churchCountry,
					churchHouseNumber: this.state.churchHouseNumber,
					churchStreet: this.state.churchStreet,
					churchPinCode: this.state.churchPinCode,
					churchPreacherFirstName: this.state.churchPreacherFirstName,
					churchPreacherLastName: this.state.churchPreacherLastName,
					churchCity: this.state.churchCity,
					churchPhoneNumber: this.state.churchPhoneNumber,
				})

				.then(() => {
					alert("user saved");
					this.setState({ showComponent: false });
					firebase
						.auth()
						.signOut()
						.then(() => {
							console.log("Signedout");
						})
						.catch((error) => {
							// An error happened.
						});
				})
				.catch((err) => {
					console.error(err);
					this.setState({
						errors: this.state.errors.concat(err),
						loading: false,
					});
				});
		} else {
			console.log("not valid");
		}
	};

	render() {
		const {
			churchArea,
			churchDob,
			churchCount,
			churchServiceTime,
			churchBibleClassDay,
			churchbibleClasstime,
			churchPreacherFirstName,
			churchPreacherLastName,
			churchCountry,
			churchHouseNumber,
			churchStreet,
			churchCity,
			churchPinCode,
			churchPhoneNumber,
			churchEmail,
			password,
			passwordConfirmation,
		} = this.state;

		let adminComponent;
		if (this.state.showComponent) {
			adminComponent = (
				<div>
					<Row>
						<Col xl={{ span: 2, offset: 5 }}>
							<h3>Church Area</h3>
						</Col>
					</Row>

					<Col xl={{ span: 6, offset: 3 }}>
						<Form onSubmit={this.handleSubmit} size="large">
							<Segment stacked>
								<Form.Field>
									<label>Area</label>

									<Form.Input
										disabled
										fluid
										name="churchArea"
										icon="user"
										iconPosition="left"
										placeholder={churchArea}
										onChange={this.handleChange}
										value={firebase.auth().currentUser.displayName}
										type="text"
									/>
								</Form.Field>
								<label>Name of Preacher</label>
								<Form.Group widths="equal">
									<Form.Input
										fluid
										name="churchPreacherFirstName"
										icon="user"
										iconPosition="left"
										placeholder={churchPreacherFirstName}
										onChange={this.handleChange}
										value={churchPreacherFirstName}
										type="text"
									/>
									<Form.Input
										fluid
										name="churchPreacherLastName"
										icon="user"
										iconPosition="left"
										placeholder={churchPreacherLastName}
										onChange={this.handleChange}
										value={churchPreacherLastName}
										type="text"
									/>
								</Form.Group>
								<Form.Field>
									<label>Date Of Innaguration</label>
									<Form.Input
										fluid
										name="churchDob"
										icon="date"
										iconPosition="left"
										placeholder={churchDob}
										onChange={this.handleChange}
										value={churchDob}
										type="date"
									/>
								</Form.Field>

								<Form.Field>
									<label>No. of Members</label>

									<Form.Input
										fluid
										name="churchCount"
										icon="date"
										iconPosition="left"
										placeholder={churchCount}
										onChange={this.handleChange}
										value={churchCount}
										type="text"
									/>
								</Form.Field>
								<Form.Field>
									<label>Sunday Service Timings</label>

									<Form.Input
										fluid
										name="churchServiceTime"
										icon="date"
										iconPosition="left"
										placeholder={churchServiceTime}
										onChange={this.handleChange}
										value={churchServiceTime}
										type="time"
									/>
								</Form.Field>

								<Form.Field>
									<label>Weekly Buible Class Timings</label>

									<Form.Group widths="equal">
										<Form.Dropdown
											placeholder={churchBibleClassDay}
											name="churchBibleClassDay"
											fluid
											search
											selection
											onChange={this.handleBibleClassDay}
											options={this.state.bibleClassDay}
										/>

										<Form.Input
											fluid
											name="churchBibleClasstime"
											icon="date"
											iconPosition="left"
											placeholder={churchbibleClasstime}
											onChange={this.handleChange}
											value={churchbibleClasstime}
											type="time"
										/>
									</Form.Group>
								</Form.Field>

								<Form.Field>
									<label>Address</label>

									<Form.Input
										fluid
										name="churchHouseNumber"
										icon="date"
										iconPosition="left"
										placeholder={churchHouseNumber}
										onChange={this.handleChange}
										value={churchHouseNumber}
										type="text"
									/>
									<Form.Input
										fluid
										name="churchStreet"
										icon="date"
										iconPosition="left"
										placeholder={churchStreet}
										onChange={this.handleChange}
										value={churchStreet}
										type="text"
									/>
									<Form.Input
										fluid
										name="churchCity"
										icon="date"
										iconPosition="left"
										placeholder={churchCity}
										onChange={this.handleChange}
										value={churchCity}
										type="text"
									/>
									<Form.Input
										fluid
										name="churchPhoneNumber"
										icon="date"
										iconPosition="left"
										placeholder={churchPhoneNumber}
										onChange={this.handleChange}
										value={churchPhoneNumber}
										type="text"
									/>
								</Form.Field>

								<Button color="orange" fluid size="large">
									Submit
								</Button>
							</Segment>
						</Form>
					</Col>
				</div>
			);
		}

		return <div>{adminComponent}</div>;
	}
}

export default Admin;
