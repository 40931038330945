/* eslint-disable no-unused-vars */
import React from "react";
import "../App.css";
import Admin from "./Admin";
import firebase from "../firebase";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import csc from "country-state-city";
import { Form, Segment, Button, Message } from "semantic-ui-react";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			errors: [],
			loading: false,
			isSignedIn: false,
			churchArea: "",
			churchDob: "",
			churchCount: "",
			churchServiceTime: "",
			churchbibleClassDay: "",
			churchbibleClasstime: "",
			churchCountry: "",
			churchState: "",
			churchHouseNumber: "",
			churchStreet: "",
			churchCity: "",
			churchPhoneNumber: "",
			churchPreacherLastName: "",
			churchPreacherFirstName: "",
			countryOptions1: [],
			stateOptions1: [{ key: "null", text: "Select Country First" }],
			churches: [],
			show: true,
		};
	}
	componentDidMount() {
		firebase
			.auth()
			.signOut()
			.then(() => {
				// console.log("Signedout");
			})
			.catch((error) => {
				// An error happened.
			});

		let countryOptions1 = csc.getAllCountries().map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.isoCode,
			};
		});

		this.setState({ countryOptions1: countryOptions1 });
	}
	handleChangeCountry = (event, data) => {
		this.setState({ churchCountry: csc.getCountryByCode(data.value).name });
		// console.log(this.state.churchCountry);
		let stateOptions1 = csc.getStatesOfCountry(data.value).map((index) => {
			return {
				key: index.isoCode,
				text: index.name,
				value: index.name,
			};
		});
		this.setState({ stateOptions1: stateOptions1 });
	};
	handleChangeState = (event, data) => {
		this.setState({ churchState: data.value });
	};

	displayErrors = (errors) =>
		errors.map((error, i) => <p key={i}>{error.message}</p>);

	handleChange = (event) => {
		if (event.target.name === "churchArea")
			event.target.value =
				event.target.value.charAt(0).toLowerCase() +
				event.target.value.slice(1);

		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		// console.log(this.state.churchArea);
		if (this.isFormValid(this.state)) {
			this.setState({ errors: [], loading: true });
			firebase
				.auth()
				.signInWithEmailAndPassword(this.state.email, this.state.password)
				.then((signedInUser) => {
					// console.log(signedInUser);
					// console.log(this.state.churchCountry, this.state.churchState);
					this.setState({
						loading: false,
						isSignedIn: true,
						churchArea: firebase.auth().currentUser.displayName,
						show: false,
					});

					firebase
						.database()
						.ref(
							"churches/" +
								this.state.churchCountry +
								"/" +
								this.state.churchState +
								"/" +
								this.state.churchArea
						)
						.once("value")
						.then((snapshot) => {
							let churches = [snapshot.val()];

							this.setState({
								churches: churches,
							});
							console.log(snapshot.val());
						})
						.catch((err) => {
							// console.error(err);
							this.setState({
								errors: this.state.errors.concat(err),
								loading: false,
							});
						});
				})
				.catch((err) => {
					// console.error(err);
					this.setState({
						errors: this.state.errors.concat(err),
						loading: false,
					});
				});
		} else {
			this.setState({
				errors: this.state.errors.concat({
					message: "Sorry, Please enter valid information",
				}),
			});
		}
	};

	isFormValid = ({ email, password }) => email && password;

	handleInputError = (errors, inputName) => {
		return errors.some((error) =>
			error.message.toLowerCase().includes(inputName)
		)
			? "error"
			: "";
	};

	userVerified() {
		// console.log("hello");
		return (
			<div>
				<Admin />
			</div>
		);
	}

	render() {
		const { email, password, errors, loading, churchArea } = this.state;

		let loginComponent;
		if (this.state.show) {
			loginComponent = (
				<div>
					<Form onSubmit={this.handleSubmit}>
						<Col xl={{ span: 4, offset: 4 }} xs={{ span: 10, offset: 1 }}>
							<Segment stacked>
								<h2 style={{ textAlign: "center" }}>Login</h2>
								<Row className="eachRow">
									<Col xl={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2 }}>
										<Form.Dropdown
											placeholder="Select Country"
											name="churchCountry"
											fluid
											search
											selection
											onChange={this.handleChangeCountry}
											options={this.state.countryOptions1}
										/>
									</Col>
								</Row>
								<Row className="eachRow">
									<Col xl={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2 }}>
										<Form.Dropdown
											placeholder="Select Province/State"
											name="churchState"
											fluid
											search
											selection
											onChange={this.handleChangeState}
											options={this.state.stateOptions1}
										/>
									</Col>
								</Row>
								<Row className="eachRow">
									<Col xl={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2 }}>
										<Form.Input
											fluid
											name="email"
											icon="mail"
											iconPosition="left"
											placeholder="Email Address"
											onChange={this.handleChange}
											value={email}
											className={this.handleInputError(errors, "email")}
											type="email"
										/>
									</Col>
								</Row>
								<Row className="eachRow">
									<Col xl={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2 }}>
										<Form.Input
											fluid
											name="password"
											icon="lock"
											iconPosition="left"
											placeholder="Password"
											onChange={this.handleChange}
											value={password}
											className={this.handleInputError(errors, "password")}
											type="password"
										/>
									</Col>
								</Row>
								<Row className="eachRow">
									<Col xl={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2 }}>
										<Button
											disabled={loading}
											className={loading ? "loading" : ""}
											color="violet"
											fluid
											size="large"
										>
											Submit
										</Button>
									</Col>
								</Row>
							</Segment>
						</Col>
					</Form>

					<Row>
						<Col xl={{ span: 4, offset: 4 }}>
							{errors.length > 0 && (
								<Message error>
									<h3>Error</h3>
									{this.displayErrors(errors)}
								</Message>
							)}
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<div className="loginForm">
				{loginComponent}
				<Row>
					<Col>
						{" "}
						{this.state.churches.map((churchInfo, index) => {
							return <Admin key={index} churchInfo={churchInfo} />;
						})}
					</Col>
				</Row>
			</div>
		);
	}
}

export default Login;
