/* eslint-disable no-unused-vars */
import React from "react";

import firebase from "../firebase";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "./Form";
import Step4 from "./step4";

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step1ButtonColor: "grey",
			step2ButtonColor: "grey",
			step1: false,
			step2: false,
			churchArea: "",
			churchState: "",
			churchCountry: "",
			showStepFour: false,
			show: true,
		};
	}
	componentDidMount() {
		firebase
			.auth()
			.signOut()
			.then(() => {
				// console.log("Signedout");
			})
			.catch((error) => {
				// An error happened.
			});
	}

	stepOneClicked = () => {
		this.setState({ step1: !this.state.step1 });
		if (this.state.step1ButtonColor === "grey") {
			this.setState({ step1ButtonColor: "#4DD637" });
		} else {
			this.setState({ step1ButtonColor: "grey" });
		}
	};
	stepTwoClicked = () => {
		this.setState({ step2: !this.state.step2 });
		if (this.state.step2ButtonColor === "grey") {
			this.setState({ step2ButtonColor: "#4DD637" });
		} else {
			this.setState({ step2ButtonColor: "grey" });
		}
	};
	getInfoFromForm = (val) => {
		// console.log(val);
		val = val.charAt(0).toLowerCase() + val.slice(1);
		this.setState({
			churchArea: val,
			showStepFour: true,
		});
	};
	revealStepFour = () => {
		if (this.state.showStepFour) {
			// console.log("hello");
			return <Step4 />;
		}
	};

	stepFourRemove = (val) => {
		// console.log(val);
		this.setState({
			showStepFour: val,
		});
	};
	getSubmissionInfoFromForm = (val) => {
		this.setState({
			show: val,
		});
	};

	render() {
		let registerComponent;
		if (this.state.show) {
			registerComponent = (
				<div>
					<Row style={{ textAlign: "center" }}>
						<Col>
							<h1>Rgistration</h1>
						</Col>
					</Row>
					<Row>
						<Col xl={4} className="registerDivs">
							{/* <Row>
								<Col
									xl={{ span: 10, offset: 1 }}
									style={{ textAlign: "center" }}
								>
									<h1>Being an Admin</h1>
								</Col>
							</Row> */}
							<Row>
								<Col xl={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
									<div className="register-middle">
										<h2 style={{ textAlign: "center" }}>As an Admin</h2>
										<p>
											To be an Admin of a Congregation, You must agree to the
											following
											<br />
											1. I have knowledge to handle the page <br />
											2. I am authorized by the Church to perform this task{" "}
											<br />
											3. I give only the true information.
											<br />
										</p>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									xl={{ span: 6, offset: 3 }}
									style={{ textAlign: "center" }}
								>
									<button
										style={{
											backgroundColor: this.state.step1ButtonColor,
											padding: 10,
											marginTop: 20,
										}}
										onClick={this.stepOneClicked}
									>
										Agree
									</button>
								</Col>
							</Row>
						</Col>

						<Col xl={4} className="registerDivs">
							{/* <Row>
								<Col
									xl={{ span: 10, offset: 1 }}
									style={{ textAlign: "center", backgroundColor: "red" }}
								>
									<h1>Agreements</h1>
								</Col>
							</Row> */}
							<Row>
								<Col xl={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 1 }}>
									<div className="register-middle">
										<h2 style={{ textAlign: "center" }}>As a Church</h2>
										<p>
											Before you Register, please go through these conditions.
											Click Yes only if you agree with all these lines as a
											Church
											<br />
											1. Name of our Congregation is Church Of Christ
											<br />
											2. We don't use musical instruments in our Church
											<br />
											3. We don't celebrate any festivals. Sunday is the most
											Prominent day for us.
											<br />
											4. We break the bread as Lord Supper on every Sunday
											<br />
											5. We strictly don't allow Women to lead the service
											except in Ladies class
										</p>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									xl={{ span: 6, offset: 3 }}
									style={{ textAlign: "center" }}
								>
									<button
										style={{
											backgroundColor: this.state.step2ButtonColor,
											padding: 10,
											marginTop: 20,
										}}
										onClick={this.stepTwoClicked}
									>
										Yes, continue
									</button>
								</Col>
							</Row>
						</Col>

						<Col xl={4}>
							<Row>
								<Col xl={12}></Col>
							</Row>
							<Row>
								<Col>
									<div>
										<Form
											stepOneClicked={this.state.step1}
											stepTwoClicked={this.state.step2}
											onChangeValue={this.getInfoFromForm}
											onSubmission={this.getSubmissionInfoFromForm}
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<div>
				{registerComponent}
				<Row>
					<Col>
						{this.state.showStepFour && (
							<Step4
								className="registerDivs"
								churchArea={this.state.churchArea}
								stepFourRemove={this.stepFourRemove}
							/>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}

export default Register;
