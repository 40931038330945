/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import firebase from "../firebase";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import icon from "../assets/icon.png";
import "../App.css";

class Card extends React.Component {
	constructor(props) {
		super(props);
		// this.state = {
		//     churchArea: "",
		//     churchDob: "",
		//     churchCount: "",
		//     churchServiceTime: "",
		//     churchbibleClassDay: "",
		//     churchbibleClasstime: "",
		//     churchCountry: "",
		//     churchHouseNumber: "",
		//     churchStreet: "",
		//     churchCity: "",
		//     churchPhoneNumber: "",
		//     churchEmail: "",
		//     password: "",
		//     passwordConfirmation: "",
		//     errors: [],
		//     loading: false,
		//     usersRef: firebase.database().ref("users"),
		//     countryOptions1: [],
		//     stateOptions1: [{ key: "null", text: "Select Country First" }]
		// };
		this.state = {
			churches: [],
		};
	}

	infoToUpper = (info) => {
		info = info.charAt(0).toUpperCase() + info.slice(1);
		return info;
	};

	render() {
		return (
			<div className=" result-card">
				<Col xl={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 1 }}>
					<Row>
						<Col xl={12}>
							<Row>
								<Col xl={12}>
									<div className="card-churchArea">
										<h1>
											{this.infoToUpper(this.props.churchInfo.churchArea)}{" "}
											Church Of Christ
										</h1>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xl={12} className="card-churchAddress">
									<p>
										{this.props.churchInfo.churchHouseNumber},{" "}
										{this.props.churchInfo.churchStreet},{" "}
										{this.infoToUpper(this.props.churchInfo.churchArea)},{" "}
										{this.infoToUpper(this.props.churchInfo.churchCity)},{" "}
										{this.props.churchInfo.churchPinCode},{" "}
										{this.infoToUpper(this.props.churchInfo.churchCountry)}
									</p>
								</Col>
							</Row>

							<br />
							<Row>
								<Col xl={4}>
									<Row>
										<Col className="card-churchAddress">
											<h3>Preacher</h3>
										</Col>
									</Row>
									<Row>
										<Col className="card-churchAddress">
											<p>
												{this.props.churchInfo.churchPreacherFirstName}{" "}
												{this.props.churchInfo.churchPreacherLastName}
											</p>
										</Col>
									</Row>
								</Col>
								<Col xl={4}>
									<Row>
										<Col className="card-churchAddress">
											<h3>Worship time</h3>
										</Col>
									</Row>
									<Row>
										<Col className="card-churchAddress">
											{this.props.churchInfo.churchServiceTime}
										</Col>
									</Row>
								</Col>
								<Col xl={4}>
									<Row>
										<Col className="card-churchAddress">
											<h3>Contact Number</h3>
										</Col>
									</Row>
									<Row>
										<Col className="card-churchAddress">
											<p>{this.props.churchInfo.churchPhoneNumber}</p>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col xl={6}>
									<Row>
										<Col className="card-churchAddress">
											<h3>Bible Class Day</h3>
										</Col>
									</Row>
									<Row>
										<Col className="card-churchAddress">
											<p>{this.props.churchInfo.churchBibleClassDay}</p>
										</Col>
									</Row>
								</Col>
								<Col xl={6}>
									<Row>
										<Col className="card-churchAddress">
											<h3>Bible Class Time</h3>
										</Col>
									</Row>
									<Row>
										<Col className="card-churchAddress">
											<p>{this.props.churchInfo.churchbibleClasstime}</p>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</div>
		);
	}
}

export default Card;
