import { Col, Row } from "react-bootstrap";

function Aim() {
	return (
		<Row>
			<Col
				xl={{ span: 6, offset: 3 }}
				xs={{ span: 10, offset: 1 }}
				style={{ backgroundColor: "White", marginTop: "50px" }}
			>
				<h2>What is ICCOFC</h2>
				<p>
					Firstly, when we see the logo, it has three lines.
					<br />
					Line 1 "Inter Continental", Line 2 “Churches of Christ" and Line 3 "we
					serve you".
					<br />
					Line 1 indicates the place of the Church. For example, we have
					"Gunadala Church of Christ", in which "Gunadala" represents the area
					of the Church. In the same way, when we put "Inter Continental", that
					represents the whole planet and each and every Church of Christ on the
					planet. Either directly or indirectly, we don't have any intention to
					change or add something to the Church of Christ. <br />
					Line 2 indicates the Churches of Christ. There are number of Churches
					named “Church of Christ" across the globe. But, we include only the
					congregations strictly following The New Testament Christianity (the
					first century Christianity), the Church and there are some terms and
					agreements based on the Bible doctrine that a congregation needs to
					agree to be a part of this. <br />
					Line 3 indicates that “we serve you". So now it is clear that, this is
					not a single congregation but constitutes all the authentic Churches
					of Christ across the globe and more importantly, we are just serving
					these Churches.
				</p>
				<h2>What do we mean by serving?</h2>
				<p>
					Let us take a scenario, where in we need to move to a new place and we
					don't know where the Church of Christ is in that area. And even if we
					find one, we don't know their faith. So, when we log on to iccofc.com
					and search for a Church based on the Country and province/state, it
					shows all the Churches that were registered in there. And in the near
					future, the ICCOFC has a plan to launch a mobile app that gives
					service to each and every member of the registered Church. Those
					include the Church notification, weekly reminders, prayer requests,
					group chats etc.
				</p>
				<h2>Are we connected to any organization?</h2>
				<h5> Clearly a big NO.</h5>
				<p>
					{" "}
					ICCOFC does not have any connection with any organization. If you are
					confused with the name being close to any other organization, please
					clear up all your thoughts. The only purpose of this app is to help
					people find the authentic Churches and to save The New Testament
					Christianity (the first century Christianity), the Church for the
					generations to come.
				</p>

				<h2>Do we charge for this service?</h2>
				<p>
					{" "}
					No, we don't charge anything. Our main aim is to give service to the
					authentic Churches and their members. The only thing we ask the
					congregations is to follow the Bible doctrine and save The New
					Testament Christianity (the first century Christianity), the Church
					for the future generations.
				</p>
				<h2>
					{" "}
					Do we say that the Congregations registered here are the only ones
					that are authentic?
				</h2>
				<p>
					{" "}
					Well, the clear explanation for this is, we don't judge anyone. And
					judgement is not our work. We have a list of basic principles based on
					the Bible doctrine. And if you agree for those, we are happy to serve
					you.
				</p>

				<h4>
					{" "}
					Jude 3 <br />
					“3 Beloved, when I gave all diligence to write unto you of the common
					salvation, it was needful for me to write unto you, and exhort you
					that ye should earnestly contend for the faith which was once
					delivered unto the saints.”
				</h4>
				<h4>
					{" "}
					Ephesians 3: 20-21 <br />
					“20 Now unto him that is able to do exceeding abundantly above all
					that we ask or think, according to the power that worketh in us, 21
					Unto him be glory in the church by Christ Jesus throughout all ages,
					world without end. Amen.
				</h4>

				<h5>
					GET INTO THE HABIT OF ASKING OUR OWNSELVES "DOES THIS SUPPORT THE
					LIFE, WHICH I AM TRYING TO CREATE every day, in every aspect
				</h5>
				<p>
					We hope that we cleared most of the doubts. If you have any queries,
					please feel free to email us @
				</p>
				<h4>intercontinentalcoc@gmail.com</h4>
			</Col>
		</Row>
	);
}

export default Aim;
